import type { AsVuexStore } from '@zento-lib/components/vuex';
import ICategoryState from '@vue-storefront/core/modules/catalog/types/CategoryState';

export const KEY = 'ui';

interface IUIState extends ICategoryState {
  sidebar: boolean;
  microcart: boolean;
  wishlist: boolean;
  searchpanel: boolean;
  newsletterPopup: boolean;
  overlay: boolean;
  loader: boolean;
  authElem: string;
  checkoutMode: boolean;
  submenu: {
    /**
     * Keeps track if the currently selected submenu item is child (number)
     */
    depth: number;

    /**
     * Tracks selected submenu items ids
     */
    path: string[];
  };
  navigation: any[];
  profileDrawer: boolean;
}

export type IVSFStoreUI = AsVuexStore<
IUIState,
'getNavigation',
'toggleMicrocart' | 'toggleWishlist',
'setCheckoutMode' | 'setMicrocart' | 'setSidebar' | 'setSubmenu' |
'setWishlist' | 'setOverlay' | 'setLoader' | 'setAuthElem' | 'setNavigationInfo' | 'setProfileDrawer'
>;
